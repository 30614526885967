
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$factbanner-padding-y: $spacer * 1.25 !default;
$factbanner-padding-x: $factbanner-padding-y !default;
$factbanner-bg: $beige !default;
$factbanner-color: $brown !default;
$factbanner-border-radius: $border-radius !default;

.factlist {
  color: $factbanner-color;

  .factlist-title {
    margin-bottom: 2px;
    padding: $factbanner-padding-y $factbanner-padding-x;
    background-color: $factbanner-bg;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    > * {
      margin-bottom: 0;
    }

    ~ ul {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  ul {
    @include list-unstyled();
    padding: $factbanner-padding-y $factbanner-padding-x;
    background-color: $factbanner-bg;
    border-radius: $border-radius;

    > .fact-item {
      margin-bottom: $spacer;

      &:last-child {
        margin-bottom: 0;
      }

      .eyecatcher-icon {
        margin-bottom: 0;
      }
    }
  }
}
