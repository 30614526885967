
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$fallback-image-fallback-size: 100% !default;
$fallback-image-fallback-color: $imgfallback !default;

.fallback-image {
    img {
        display: block;
        width: 100%;
        max-width: none;
    }

    .is-fallback-image {
        .bi {
            display: block;
            margin: auto;
            width: $fallback-image-fallback-size;
            height: $fallback-image-fallback-size;
            color: $fallback-image-fallback-color;
        }
    }
}
