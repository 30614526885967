
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$factitem-color: $brown !default;

.fact-item {
  color: $factitem-color;
}
