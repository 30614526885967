
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$eyecatcher-size: 60px !default;
$eyecatcher-size-sm: 48px !default;
$eyecatcher-size-lg: 75px !default;
$eyecatcher-box-shadow: map-get($shadows, 'salebutton') !default;

$eyecatcher-svg-font-weight: $font-weight-bold !default;
$eyecatcher-svg-font-size: 100px !default;
$eyecatcher-svg-text-transform: uppercase !default;

.eyecatcher {
  display: inline-block;
  width: $eyecatcher-size;
  height: $eyecatcher-size;
  border-radius: 50%;
  box-shadow: $eyecatcher-box-shadow;
  position:relative;

  svg {
    display: inline-block;
    max-width: 100%;
    max-height:100%;
    font-weight: $eyecatcher-svg-font-weight;
    font-size: $eyecatcher-svg-font-size;
    text-transform: $eyecatcher-svg-text-transform;
    white-space: nowrap;
    line-height: 1;
    fill: currentColor;
  }

  &.eyecatcher-sm {
    width: $eyecatcher-size-sm;
    height: $eyecatcher-size-sm;
  }

  &.eyecatcher-lg {
    width: $eyecatcher-size-lg;
    height: $eyecatcher-size-lg;
  }

  @each $key, $value in $theme-colors {
    &.eyecatcher-#{$key} {
      background-color: $value;
      color: color-yiq($value);
    }
  }
}
