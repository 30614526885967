
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$discoveryteaser-image-overlay-bg: rgba($black, 0.4) !default;
$discoveryteaser-image-overlay-font-size: $font-size-base * 1.5 !default;
$discoveryteaser-image-overlay-font-weight: $font-weight-bold !default;
$discoveryteaser-image-overlay-color: color-yiq($discoveryteaser-image-overlay-bg) !default;
$discoveryteaser-image-overlay-letter-spacing: $letter-spacing-2 !default;
$discoveryteaser-image-overlay-transition: opacity ease-out 300ms !default;

.discovery-teaser {
  display: block;
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  .discovery-teaser-image {
    position: relative;

    img {
      display: block;
      width: 100%;
      max-width: none;
    }

    .image-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      inset: 0;
      background-color: $discoveryteaser-image-overlay-bg;
      opacity: 0;
      font-size: $discoveryteaser-image-overlay-font-size;
      font-weight: $discoveryteaser-image-overlay-font-weight;
      color: $discoveryteaser-image-overlay-color;
      letter-spacing: $discoveryteaser-image-overlay-letter-spacing;
      transition: $discoveryteaser-image-overlay-transition;
    }
  }

  .discovery-teaser-content {
  }

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;

    .discovery-teaser-image {
      .image-overlay {
        opacity: 1;
      }
    }
  }
}
