
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.grid-mixed {
  @include component('y');

  .grid-mixed-row {
    margin-top: $paragraph-margin-bottom * -0.5;
    margin-bottom: $paragraph-margin-bottom * -0.5;

    > .grid-mixed-col {
      margin-top: $paragraph-margin-bottom * 0.5;
      margin-bottom: $paragraph-margin-bottom * 0.5;
    }
  }

  + .grid-mixed {
    .grid-mixed-row {
      margin-top: $paragraph-margin-bottom * -1;
    }
  }
}
