
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$eyecatericon-gap: $spacer !default;

$eyecatericon-icon-size: $spacer * 3 !default;
$eyecatericon-icon-size-sm: $spacer * 2.5 !default;
$eyecatericon-icon-border: map-get($borders, 'bright') !default;
$eyecatericon-icon-font-size: $font-size-base * 1.3 !default;

$eyecatericon-text-gap: $spacer !default;

// desktop
$eyecatericon-breakpoint: $desktop-breakpoint !default;

$eyecatericon-desktop-icon-size: $spacer * 4 !default;
$eyecatericon-desktop-icon-size-sm: $spacer * 3.5 !default;
$eyecatericon-desktop-icon-border: $eyecatericon-icon-border !default;
$eyecatericon-desktop-icon-font-size: $font-size-base * 1.8 !default;

$eyecatericon-desktop-text-gap: $eyecatericon-text-gap   !default;

.eyecatcher-icon {
  display: inline-flex;
  margin-bottom: $eyecatericon-gap;

  .icon-icon {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $eyecatericon-icon-size;
    height: $eyecatericon-icon-size;
    border: $eyecatericon-icon-border;
    border-radius: 100%;
    font-size: $eyecatericon-icon-font-size;
  }

  .icon-text {
    margin-left: $eyecatericon-text-gap;
    align-self: center;
  }

  .icon-icon {
    &.icon-sm {
      width: $eyecatericon-icon-size-sm;
      height: $eyecatericon-icon-size-sm;
    }
  }

  @include media-breakpoint-up($eyecatericon-breakpoint) {
    .icon-icon {
      width: $eyecatericon-desktop-icon-size;
      height: $eyecatericon-desktop-icon-size;
      border: $eyecatericon-desktop-icon-border;
      font-size: $eyecatericon-desktop-icon-font-size;

      &.icon-sm {
        width: $eyecatericon-desktop-icon-size-sm;
        height: $eyecatericon-desktop-icon-size-sm;
      }
    }

    .icon-text {
      margin-left: $eyecatericon-desktop-text-gap;
    }
  }
}
