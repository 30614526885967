
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$testimonial-padding-y: 0 !default;
$testimonial-padding-x: $testimonial-padding-y !default;

$testimonial-avatar-gap: $paragraph-margin-bottom !default;

$testimonial-avatar-image-size: 72px !default;

$testimonial-avatar-info-gap: $spacer !default;

$testimonial-avatar-name-font-size: $font-size-base !default;
$testimonial-avatar-name-font-weight: $font-weight-bold !default;
$testimonial-avatar-name-color: inherit !default;

$testimonial-avatar-text-font-size: $testimonial-avatar-name-font-size !default;
$testimonial-avatar-text-font-weight: $font-weight-normal !default;
$testimonial-avatar-text-color: $dark !default;

// desktop
$testimonial-desktop: $desktop-breakpoint !default;

$testimonial-desktop-padding-y: $grid-gutter-width !default;
$testimonial-desktop-padding-x: $testimonial-desktop-padding-y !default;

.testimonial {
  padding: $testimonial-padding-y $testimonial-padding-x;

  .testimonial-avatar {
    display: flex;
    align-items: center;
    margin-bottom: $testimonial-avatar-gap;

    .avatar-image {
      width: $testimonial-avatar-image-size;
      height: $testimonial-avatar-image-size;
    }

    .avatar-info {
      margin-left: $testimonial-avatar-info-gap;

      .avatar-name {
        font-size: $testimonial-avatar-name-font-size;
        font-weight: $testimonial-avatar-name-font-weight;
        color: $testimonial-avatar-name-color;
      }

      .avatar-text {
        font-size: $testimonial-avatar-text-font-size;
        font-weight: $testimonial-avatar-text-font-weight;
        color: $testimonial-avatar-text-color;
      }
    }
  }

  .testimonial-content {}

  @include media-breakpoint-up($testimonial-desktop) {
    padding: $testimonial-desktop-padding-y $testimonial-desktop-padding-x;
  }
}
