
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$eyecatcherimage-size: 50vw !default;
$eyecatcherimage-max-size: 200px !default;
$eyecatcherimage-border: map-get($borders, 'bold') !default;
$eyecatcherimage-bg: $white !default;
$eyecatcherimage-transition-in: transform cubic-bezier(0.2, 1, 0.65, 2) 500ms !default;
$eyecatcherimage-transition-out: transform ease-out 200ms !default;

$eyecatcherimage-content-font-size: $lead-font-size !default;
$eyecatcherimage-content-font-weight: $font-weight-bold !default;
$eyecatcherimage-content-line-height: 1.2 !default;
$eyecatcherimage-content-text-align: center !default;
$eyecatcherimage-content-text-transform: uppercase !default;

$eyecatcherimage-hover-transform: rotate(-3deg) scale(1.1) !default;

.eyecatcher-image-wrapper {
  display: block;
  position: relative;

  img {
    width: 100%;
    max-width: none;
  }

  .eyecatcher-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    margin: auto;
    width: $eyecatcherimage-size;
    max-width: $eyecatcherimage-max-size;
    height: $eyecatcherimage-size;
    max-height: $eyecatcherimage-max-size;
    border-radius: 50%;
    background-color: $eyecatcherimage-bg;
    transition: $eyecatcherimage-transition-out;

    .eyecatcher-image-border {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      border: $eyecatcherimage-border;
      width: 90%;
      height: 90%;

      .eyecatcher-image-content {
        display: block;
        width: min-content;
        font-size: $eyecatcherimage-content-font-size;
        font-weight: $eyecatcherimage-content-font-weight;
        line-height: $eyecatcherimage-content-line-height;
        text-decoration: none;
        text-align: $eyecatcherimage-content-text-align;
        text-transform: $eyecatcherimage-content-text-transform;
        word-spacing: $eyecatcherimage-max-size;
      }
    }
  }

  &:hover {
    .eyecatcher-image {
      transform: $eyecatcherimage-hover-transform;
      transition: $eyecatcherimage-transition-in;
    }
  }
}
